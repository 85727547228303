<template>
  <div>

    <h1 class="mb-5">Herramientas de administrador</h1>

    <h3>
      Descargar logos
      <span class="grey--text font-weight-light">( {{ downloadLogosCount }} of {{ institutionsCount }} )</span>
    </h3>
    <v-btn 
      @click="downloadLogos" 
      class="mt-2"
      small
    >
      Descargar
    </v-btn>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ID
            </th>
            <th class="text-left">
              Message
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ins in downloadLogosResult"
            :key="ins.ins_id"
          >
            <td>{{ ins.ins_id }}</td>
            <td>{{ ins.message }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <!-- <table>
      <tr v-for="ins in downloadLogosResult" :key="ins.ins_id">
        <td>
          {{ ins.ins_id }}
        </td>
        <td>
          {{ ins.message }}
        </td>
      </tr>
    </table>

    <pre>
      {{ downloadLogosResult }}
    </pre> -->

    
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver, withValidation } from "vee-validate"
  import { mixinGlobal } from '@/mixins/mixinGlobal'
  
  import { mapGetters } from 'vuex'

  export default {
    name: 'AdminTools',
    components: { ValidationProvider, ValidationObserver },
    mixins: [ mixinGlobal ],
    data: () => ({
      downloadLogosResult: [],
      downloadLogosCount: 0,
      userTypes: ['admin', 'editor', 'suscriber'],
    }),
    mounted () {
      this.$store.dispatch('loadInstitutions')
    },
    computed: {
      ...mapGetters(['getInstitutions']),
      institutionsCount: function(){
        return this.getInstitutions.length
      },
    },
    methods: {
      async downloadLogos(){
        this.downloadLogosResult = []
        this.downloadLogosCount = 0
        for( const ins of this.getInstitutions ){
          this.downloadLogosCount += 1
          if( !ins.ins_logo_offline_filename && ins.ins_url_logo ){
            // && this.getFileExtension(ins.ins_url_logo).toUpperCase() == 'JPG'
            await this.downloadLogosApiCall(ins)
          }
        }
      },
      async downloadLogosApiCall(ins){
        await(
          new Promise( resolve => {          
            this.$axios.put('ins_instituciones', { 
              ins_id: ins.ins_id, 
              ins_url_logo: ins.ins_url_logo,
              ins_logo_offline_filename: ins.ins_logo_offline_filename  
            } )
            .then( (respuesta) =>{  
              if ( typeof respuesta.data.data === 'object' && respuesta.data.data !== null ){
                this.downloadLogosResult.push( { ins_id: respuesta.data.data[0].ins_id, message: respuesta.data.message } )   
              }else{
                this.downloadLogosResult.push( { ins_id: ins.ins_id, message: respuesta.data } )
              }  
              resolve()
            })  
            .catch( (error) =>{
              let  message = '';
              if (error.response.data.hasOwnProperty('message')) {
                message = error.response.data.message;
              }else{
                message = error
              }
              this.downloadLogosResult.push( { ins_id: ins.ins_id, message: message } )
              //this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
              resolve()
            })   
          })
        )        
      },
      getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
      }
    },
  }  
</script>